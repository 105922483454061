<i18n locale="th" lang="yaml" src="@i18n/service/service.th.yaml"></i18n>
<i18n locale="th" lang="yaml" src="@i18n/service/service-job.th.yaml"></i18n>
<i18n locale="th" lang="yaml" >
service_card.field.claim_request : "เคลม"
service_card.field.open_type : "รับจาก"
service_card.field.return_type : "ส่งคืน"
service_card.field.return_type.refer : "ส่งต่อ"
service_card.field.status.service : "ใบงาน"
service_card.field.status.repair : "งานซ่อม"
service_card.field.status.payment : "ชำระเงิน"
service_card.field.status.claim : "เคลม"
service_card.view.tip : "ดูข้อมูลใบซ่อม"
service_card.refer.tip : "เปิดใบซ่อม"
</i18n>

<template>
	<div class="service-card mycard">
		<a-skeleton v-if="showSkeleton" active />
		<template v-else>
			<div class="service-card-header service-row">
				<div class="left">
					<div class="service-field-no">
						{{service.serviceNo}}

						<my-router-link v-if="canViewDetail" :name="serviceLinkName" :param-value="service.id"
							class="service-action-icon" :target="linkTarget">
							<MyTooltip  display-mode="white" :title="$t('service_card.view.tip')" trigger="hover">
								<a-icon type="search" />
							</MyTooltip>
						</my-router-link>

						<my-router-link v-if="canRefer" name="service/refer" :param-value="service.id"
							class="service-action-icon" :target="linkTarget">
							<MyTooltip display-mode="white" :title="$t('service_card.refer.tip')" trigger="hover">
								<ReferIcon />
							</MyTooltip>
						</my-router-link>
					</div>
					<div class="text-detail">
						{{$dayjs(service.serviceOpenDate).format("D MMM YY")}}
					</div>
				</div>
				<div class="right service-field-company">
					<ImageName v-if="!hideCompany" :name="serviceData.company.name" :src="serviceData.company.image_url"
						size="very-tiny">
						<a-icon type="shop" />
					</ImageName>

					<my-router-link v-if="serviceData.droneData.drone" name="drone/view" :param-value="serviceData.droneData.drone.serialNo" param-name="serial_no"
						:target="linkTarget" class="service-field-drone">
						<MyTooltip :title="serviceData.droneData.drone.name"  trigger="hover">
							{{serviceData.droneData.drone.serialNo}}
						</MyTooltip>
					</my-router-link>
				</div>
			</div>
			<div class="service-card-content">
				<div class="service-detail">
					<div class="row">
						<label>{{$t('service.field.open_by')}}</label>
						<span v-if="serviceData.reportBy.user.username" class="value">
							{{serviceData.reportBy.user.username}}
						</span>
					</div>
					<div v-if="openTypeDisplay" class="row">
						<label>{{$t('service_card.field.open_type')}}</label>
						<span class="value">
							{{openTypeDisplay}}
						</span>
					</div>
					<div v-if="showReturn" class="row">
						<label>{{returnTypeLabel}}</label>
						<span class="value">
							{{returnTypeDisplay}}
						</span>
					</div>
					<div v-if="hasClaimNo" class="row">
						<label>{{$t('service_card.field.claim_request')}}</label>
						<span class="value">
							{{service.serviceClaimClaimNo}}
						</span>
					</div>
				</div>
				<div class="service-status-list">
					<div class="row ">
						<StatusDot
							class="service-field-status"
							:status="$tenum('service.status',service.serviceStatus)"
							:description="$tenum('service.status.' + service.serviceStatus + '.description')"
							:reason="service.serviceStatusReason"
							:icon-mode="serviceStatusDotMode" />
					</div>
					<div v-if="repairStatus" class="row">
						<label>
							<RepairIcon class="anticon" />
							{{$t('service_card.field.status.repair')}}
						</label>
						<StatusDot class="value"
							:status="$tenum('service.repair.result',repairStatus.result)"
							:reason="repairStatus.reason"
							:icon-mode="repairStatusDotMode" />
					</div>
					<div v-if="paymentStatus" class="row">
						<label>
							<PaymentIcon class="anticon" />
							{{$t('service_card.field.status.payment')}}
						</label>
						<StatusDot class="value"
							:status="$tenum('service.payment.status',paymentStatus)"
							:icon-mode="paymentStatusDotMode" />
					</div>
					<div v-if="claimStatus" class="row">
						<label>
							<a-icon type="flag" />
							{{$t('service_card.field.status.claim')}}
						</label>
						<StatusDot class="value"
							:status="$tenum('claim.status',claimStatus)"
							:description="$tenum('claim.status',claimStatus + '.description')"
							:icon-mode="claimStatusDotMode" />
					</div>
				</div>
			</div>
			<div class="service-card-footer service-row text-detail">
				<div class="left">
					{{serviceJobsDetail}}
				</div>
				<div class="right">
					<DayFromNowText :value="service.serviceUpdatedDatetime"
								:prefix="$t('service.field.updated')"
								:additional-tooltip="updatedBy"/>
				</div>
			</div>
		</template>
	</div>
</template>

<script>
import {Skeleton} from "ant-design-vue"
import DayFromNowText from "@components/common/DayFromNowText.vue"
import ReferIcon from "vue-material-design-icons/TableArrowRight.vue"
import StatusDot from "@components/common/StatusDot.vue"
import MyTooltip from "@components/common/MyTooltip.vue"
import ImageName from "@components/common/ImageName.vue"
import LoginInfoMixin from "@mixins/LoginInfoMixin.vue"
import PaymentIcon from "vue-material-design-icons/CurrencyBtc.vue"
import RepairIcon from "vue-material-design-icons/HammerScrewdriver.vue"

import { SERVICE_STATUS, SERVICE_STATUS_FOR_PAYMENT, PAYMENT_STATUS, RETURN_TYPE } from '@utils/serviceUtil'
import { emptyServiceCardData } from '@utils/objectUtil'
import { lookupStatusDotMode ,isStatusAllowView,getOpenTypeDisplay,getReturnTypeDisplay,isReferable} from '@/src/utils/serviceUtil'
export default {
	components : {
		"a-skeleton" : Skeleton,
		DayFromNowText , StatusDot , MyTooltip, ImageName,
		ReferIcon, PaymentIcon, RepairIcon,
	} ,
	mixins : [LoginInfoMixin] ,
	props : {
		serviceData : {
			type : null,
			default : () => emptyServiceCardData()
		} ,
		loading : {
			type : Boolean,
			default : false,
		} ,
		linkTarget : {
			type : String,
			default : "_self"
		} ,
		hideCompany : {
			type : Boolean ,
			default : false,
		} ,
		viewMode : {
			type : String,
			default : '',
		}
	} ,
	computed : {
		isValid() {
			return this.serviceData.id > 0
		} ,
		service() {
			return this.isValid ? this.serviceData.service : {}
		} ,
		showSkeleton() {
			return this.loading || !this.isValid
		} ,
		canViewDetail() {
			if (!this.isValid)
				return false
			else if (this.$authorize('read','service',{companyId : this.service.serviceCompanyId}))
				return true
			else
				return isStatusAllowView('details',this.service.serviceStatus)
		} ,
		canRefer() {
			if (!this.isValid || !this.$authorize('create','service'))
				return false
			else
				return isReferable(this.serviceData,this.currentCompany.id)
		} ,
		hasClaimNo() {
			return this.$notEmpty(this.service.serviceClaimClaimNo)
		} ,
		paymentStatus() {
			if (!this.service.servicePaymentStatus && Object.values(SERVICE_STATUS_FOR_PAYMENT).includes(this.service.serviceStatus)) {
				return PAYMENT_STATUS.STATUS_NEW
			}
			return this.service.servicePaymentStatus
		} ,
		repairStatus() {
			return {
				result: this.service.serviceRepairRepairResult,
				reason: this.service.serviceRepairRepairResultReason
			}
		} ,
		claimStatus() {
			return this.service.serviceClaimStatus
		} ,
		serviceStatusDotMode() {
			if (this.isValid)
				return lookupStatusDotMode('service',this.service.serviceStatus)
			else
				return null
		} ,
		paymentStatusDotMode() {
			if (this.paymentStatus)
				return lookupStatusDotMode('payment',this.paymentStatus)
			else
				return null
		} ,
		repairStatusDotMode() {
			if (this.repairStatus)
				return lookupStatusDotMode('repair',this.repairStatus.result)
			else
				return null
		} ,
		claimStatusDotMode() {
			return lookupStatusDotMode('claim',this.claimStatus)
		} ,
		openTypeDisplay() {
			return getOpenTypeDisplay(this,this.serviceData)
		} ,
		showReturn() {
			return this.isValid && this.service.serviceStatus === SERVICE_STATUS.STATUS_CLOSE
		} ,
		returnTypeLabel() {
			if (!this.showReturn)
				return null
			const returnType = this.service.serviceReturnReturnType
			if (returnType === RETURN_TYPE.REFER)
				return this.$t('service_card.field.return_type.refer')
			else
				return this.$t('service_card.field.return_type')
		} ,
		returnTypeDisplay() {
			return this.showReturn ? getReturnTypeDisplay(this,this.serviceData) : null
		} ,
		serviceJobsDetail() {
			const detailList = []
			if (this.service.firmware) {
				detailList.push(this.$t('service.jobs.firmware'))
			}
			if (this.service.serviceMaintenanceUpdatedDatetime) {
				detailList.push(this.$t('service.jobs.maintenance'))
			}
			if (this.service.parts.recall > 0) {
				detailList.push(this.$t('service.jobs.recall_parts'))
			}
			if (this.service.parts.normal > 0) {
				detailList.push(this.$t('service.jobs.normal_parts'))
			}
			if (this.repairStatus) {
				detailList.push(this.$t('service.jobs.repair'))
			}
			return detailList.join(',')
		} ,
		updatedBy() {
			return this.$t('common.by')+" "+this.serviceData.updatedBy.user.username
		} ,
		serviceLinkName() {
			return this.viewMode === 'claim' ? 'service/claim' : 'service/view'
		}
	}
}
</script>

<style lang="less" scoped>
.service-card {
	border : 1px solid @border-color-base;
	padding : 8px 12px;
	min-width: 350px !important;
	display : flex;
	flex-direction: column;
}
.service-row {
	.clearfix();
	.left {
		float : left;
	}
	.right {
		float : right;
	}
}
.service-card-header {
	margin-bottom : 4px;
}
.service-card-content {
	flex-grow : 1;
	min-height: 0;
	display : flex;
	flex-wrap: nowrap;

	.row {
		margin-bottom : 2px;
		label {
			color: @primary-color;
			&::after {
				content : ' :';
			}
		}
	}
}
.service-detail {
	flex-grow: 1;
	min-width: 0;
	font-size : 0.95em;
}
.service-status-list {
	flex-basis : 160px;
	min-width: 160px;
	padding : 4px 0 8px 8px;
	border-left : 1px dashed @info-color;
	font-size : 0.9em;
	text-align: right;
}
.service-field-status {
	display : block;
	font-size : 13px;
	font-weight: 600;
	margin-bottom : 4px;
}
.service-field-no {
	font-family: @font-family-title;
	font-size : 1.1em;
	color : @primary-color;
}
.service-field-drone {
	display : block;
	padding : 2px 6px;
	font-size : 0.85em;
	border : 1px dashed @primary-2;
	color : @primary-color;
	border-radius: 8px;
	margin-top : 4px;
}
.service-field-company {
	font-family: @font-family-title;
	text-align: right;
}
.text-detail {
	color : @text-muted;
	font-size : 0.8em;
}

@media(max-width:@screen-mobile) {
	.service-card {
		min-width : 100% !important;
	}
	.service-card-content {
		flex-direction: column;
	}
	.service-status-list {
		flex-basis : auto;
		min-width : 100% ;
		margin-top : 8px;
		padding : 8px 0 0 0;
		border-top : 1px dashed @info-color;
		border-left : none;
		text-align: left;
	}
}
</style>
