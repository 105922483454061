<i18n locale="th" lang="yaml" >
drone_connect.created_flag.topic : "Drone ตัวนี้ยังไม่เชื่อมต่อกับทาง HGR"
drone_connect.created_flag.description : "เนื่องจากเกิดปัญหาในการเชื่อมต่อกับทาง HGR กรุณากดปุ่มเพื่อเชื่อมต่อข้อมูลนี้ใหม่ เพื่อให้ระบบนี้สามารถทำงานร่วมกับ HGMC ได้ปกติ"
drone_connect.policy_flag.topic : "Drone ตัวนี้ยังไม่ตั้งค่า Policy กับทาง HGR"
drone_connect.policy_flag.description : "เนื่องจากอาจจะเกิดปัญหากับทาง HGR ทำให้ค่าสถานะ Policy ของ Drone นี้อาจจะไม่ตรงกับทาง HGR"

drone_connect.send.label : "ส่งใหม่"
</i18n>

<template>
	<div v-if="canView" class="drone-connect-container">
		<div v-if="canViewCreateFlag" class="drone-connect-row">
			<div class="topic">
				{{$t('drone_connect.created_flag.topic')}}
			</div>
			<div class="description">
				{{$t('drone_connect.created_flag.description')}}
			</div>
			<a-button @click="handleCreatedFlag">
				{{$t('drone_connect.send.label')}}
			</a-button>
		</div>
		<div v-if="canViewPolicyFlag" class="drone-connect-row">
			<div class="topic">
				{{$t('drone_connect.policy_flag.topic')}}
			</div>
			<div class="description">
				{{$t('drone_connect.policy_flag.description')}}
			</div>
			<a-button :disabled="canViewCreateFlag" @click="handlePolicyFlag">
				{{$t('drone_connect.send.label')}}
			</a-button>
		</div>
	</div>
</template>

<script>
import axios from "axios"
import ApiError from "@utils/errors/ApiError"
import PageMixin from "@mixins/PageMixin.vue"
export default {
	mixins : [PageMixin] ,
	props : {
		droneData : {
			type : null,
			default : () => []
		} ,
		isEnabledHgr : {
			type : Boolean,
			default : false
		}
	} ,
	data() {
		return {
			isEnabled : false,
			isSupported : false,
			connect : {},
			calling : false
		}
	} ,
	computed : {
		isValid() {
			return this.droneData.drone && this.droneData.drone.id > 0
		} ,
		canView() {
			return this.isEnabledHgr &&
				(this.canViewCreateFlag || this.canViewPolicyFlag) &&
				this.$authorize('changePolicy', 'drone')
		} ,
		canViewCreateFlag() {
			return this.droneData.connect.created_flag != 'success'
		} ,
		canViewPolicyFlag() {
			return this.droneData.connect.send_policy_flag != 'success'
		}
 	} ,
	methods : {
		handleCreatedFlag() {
			if (this.calling)
				return
			this.calling = true
			this.showPageLoading(true)
			axios.post("/api/drones/"+this.droneData.drone.id+"/resend-created-flag").then((response)=>{
				this.$router.go()
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(() => {
				this.calling = false
				this.hidePageLoading(true)
			})
		} ,
		handlePolicyFlag() {
			if (this.calling)
				return
			this.calling = true
			this.showPageLoading(true)
			axios.post("/api/drones/"+this.droneData.drone.id+"/resend-policy-flag").then((response)=>{
				this.$router.go()
			}).catch((error) => {
				this.$message.error(ApiError.getDisplayErrorMessage(this,error))
			}).finally(() => {
				this.calling = false
				this.hidePageLoading(true)
			})
		}
	}
}
</script>

<style lang="less" scoped>
.drone-connect-container {
	max-width : 1024px;
	margin : 32px auto;
	padding : 32px;
	background-color : @gray-1;
	text-align : center;
	border-radius : @border-radius-base;
	border : 1px solid @warning-color;
}
.drone-connect-row {
	margin-bottom : 24px;
	&:last-child {
		margin-bottom : 0;
	}
	.topic {
		font-family: @font-family-title;
		margin-bottom : 8px;
		font-size : 1.2em;
		color : @warning-color;
	}
	.description {
		font-size: 0.9em;
		margin-bottom : 8px;
	}
}
</style>
