export const TICKET_TABLE_MODE = {
	RECENT: 'recent',
	PENDING: 'pending',
	PROGRESS: 'progress',
}

export const TICKET_STATUS = {
	NEW: 'new',
	INVESTIGATE: 'investigate',
	PENDING: 'pending',
	CLOSE: 'close',
	CANCEL: 'cancel',
	RESOLVED: 'resolved',
	REOPEN: 'reopen',
}

export const getTicketStatusListFromMode = (mode) => {
	switch (mode) {
		case TICKET_TABLE_MODE.RECENT:
			return [TICKET_STATUS.CLOSE, TICKET_STATUS.RESOLVED];
		case TICKET_TABLE_MODE.PENDING:
			return [TICKET_STATUS.PENDING];
		case TICKET_TABLE_MODE.PROGRESS:
			return [TICKET_STATUS.NEW, TICKET_STATUS.INVESTIGATE, TICKET_STATUS.REOPEN];
		default:
			return [];
	}
}
