<template>
	<div class="note-box">
		<a-dropdown :trigger="['click']">
			<a class="note-box-action" @click="e => e.preventDefault()">
				<MenuIcon />
			</a>

			<a-menu slot="overlay" @click="handleMenu">
				<a-menu-item key="update">
					<a-icon type="edit" /> {{$t('common.update')}}
				</a-menu-item>
				<a-menu-item key="delete" class="text-error">
					<a-icon type="delete" /> {{$t('common.delete')}}
				</a-menu-item>
			</a-menu>
		</a-dropdown>

		<a-icon v-if="noteData.note.pin" type="pushpin" theme="filled" class="note-pin" />
		<QuillViewer class="note-content" :content="noteData.note.detail_rich" />
		<div class="note-info">
			<span>
				{{$t('common.by')}}
			</span>
			<my-router-link
				name="user/view" :param-value="noteData.updatedBy.user.id" :new-window="true">
				{{noteData.updatedBy.user.username}}
			</my-router-link>
			<span>
				{{$t('common.from')}}
			</span>
			<my-router-link name="company/view" :param-value="noteData.updatedBy.company.id" auth-action="read" auth-module="company"
			 :new-window="true">
			 {{noteData.updatedBy.company.name}}
			</my-router-link>
			<DayFromNowText
				class="comment-info-time"
				:value="noteData.note.updated_datetime"/>
		</div>
	</div>
</template>

<script>
import QuillViewer from "@components/common/QuillViewer.vue"
import DayFromNowText from "@components/common/DayFromNowText.vue"
import {Dropdown,Menu} from "ant-design-vue"
import MenuIcon from "vue-material-design-icons/DotsVertical.vue"
export default {
	components : {
		QuillViewer, DayFromNowText ,
		"a-dropdown" : Dropdown , "a-menu" : Menu,
		"a-menu-item" : Menu.Item, MenuIcon
	} ,
	props : {
		noteData : {
			type : null,
			default: () => []
		} ,
		canUpdate : {
			type : Boolean,
			default : false,
		} ,
		canDelete : {
			type : Boolean,
			default : false,
		}
	} ,
	methods : {
		handleMenu({key}) {
			this.$emit(key,this.noteData)
		}
	}
}
</script>

<style lang="less" scoped>
.note-box {
	padding : 12px 16px;
	width : 100%;
	align-self: flex-end;
	font-size : 0.95em;
	position: relative;
}
.note-info {
	color : @text-muted;
	padding-top : 8px;
	text-align : right;
	font-size : 0.9em;
}
.note-box-action {
	font-size : 24px;
	position : absolute;
	top : 0;
	right : 0;
}
.note-pin {
	font-size : 16px;
	color : @warning-color;
	position : absolute;
	top : 4px;
	left : 8px;
}
.note-content {
	padding : 0;
}
</style>
