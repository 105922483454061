<i18n locale="th" lang="yaml" src="@i18n/ticket/ticket.th.yaml"></i18n>
<i18n locale="th" lang="yaml">
ticket.field.resolved_result.prefix : "ผลวิเคราะห์ : "
</i18n>
<template>
	<a-card
		:loading="loading"
		:hoverable="clickable"
		:bordered="bordered"
		:class="cardCssClasses"
		@click="handleClick">
			<div class="mycard-content">
				<div class="row header-row">
					<div class="left">
						<my-router-link name="ticket/view" :param-value="ticketData.id"
							:target="linkTarget" @click.stop>
							{{ticketData.ticketNumber}}
						</my-router-link>
						<span class="ticket-field-company">
							[{{reportByCompanyName}}]
						</span>
					</div>
					<div class="right">
						<TicketStatus
							:status="ticketData.status" :reason="ticketData.statusReason"
							:trigger="tooltipTrigger"
							:tag-style="true"/>
					</div>
				</div>
				<div class="row">
					<div class="left text-detail">
						{{$dayjs(ticketData.eventTime).format("D MMM YY")}}
						<a-icon type="clock-circle" /> {{$dayjs(ticketData.eventTime).format("H:mm")}}
					</div>
					<div v-if="ticketData.problemType === 'consult' && $notEmpty(ticketData.resolvedResult)" class="right text-detail">
						{{$t('ticket.field.resolved_result.prefix')}}
						{{$tenum('ticket.resolved_result',ticketData.resolvedResult)}}
					</div>
				</div>
				<div class="row topic-row">
					<span v-if="!ticketData.droneServiceDroneInstanceId" class="drone-name-general">
						{{$t('ticket.field.general_ticket.short')}}
					</span>
					<my-router-link v-else name="drone/view"
						:param-value="ticketData.droneServiceDroneInstance.serialNo" param-name="serial_no" :target="linkTarget" class="drone-name" :title="ticketData.droneServiceDroneInstance.name"
						@click.stop>
						{{ticketData.droneServiceDroneInstance.serialNo}}
					</my-router-link>
					<span v-trim class="multiline">{{ticketData.topic}}</span>
				</div>
				<div class="row tags-row">
					<a-tag class="mytag-tiny mytag-dash">
						{{$tenum('ticket.problem_type',ticketData.problemType)}}
					</a-tag>
					<template v-if="$notEmpty(ticketData.searchTags)">
						<a-tag v-for="tag in ticketData.searchTags" :key="tag" class="mytag-info mytag-tiny">
							{{tag}}
						</a-tag>
					</template>
				</div>
				<div class="row status-row text-detail">
					<div class="left">
						{{ticketTypes}}
					</div>
					<div class="right">
						<DayFromNowText :value="ticketData.updatedTime"
							:prefix="$t('ticket.field.updated')"
							:additional-tooltip="updatedBy"/>
					</div>
				</div>
			</div>
	</a-card>
</template>

<script>
import {Tag} from"ant-design-vue"
import TicketStatus from "@components/ticket/TicketStatus.vue"
import DayFromNowText from "@components/common/DayFromNowText.vue"
import {emptyTicket} from '@utils/objectUtil'

export default {
	components : {
		TicketStatus,DayFromNowText,
		"a-tag" : Tag,
	} ,
	props : {
		ticketData : {
			type : null,
			default : () => {
				return emptyTicket()
			}
		} ,
		loading : {
			type: Boolean,
			default : false,
		} ,
		bordered : {
			type: Boolean ,
			default : false,
		} ,
		size : {
			type: String,
			default: "",
		} ,
		linkTarget : {
			type : String,
			default : "_self"
		} ,
		clickable :{
			type : Boolean,
			default : false,
		} ,
	} ,

	computed : {
		cardCssClasses() {
			return [
				"ticket-lite-card","mycard",
				{
					"mycard-sm" : this.size == 'small' ,
				}
			]
		} ,
		reportByCompanyName() {
			return this.ticketData.reportByCompany?.name
		} ,
		tooltipTrigger() {
			return this.clickable ? "hover" : "hover|click"
		} ,
		updatedBy() {
			return this.$t('common.by')+" "+this.ticketData.updatedBy.username
		} ,
		linkNewWindow() {
			return this.linkTarget == '_blank'
		} ,
		ticketTypes() {
			if (this.ticketData.ticketTypes)
				return this.ticketData.ticketTypes.map((t) => this.$tenum('ticket.ticket_type',t)).join(', ')
			else
				return null;
		}
	} ,
	methods : {

		handleClick(event) {
			if (!this.clickable)
				return;
			this.$open({name:'ticket/view',params:{id:this.ticketData.id}},this.linkTarget)
		}
	}
}
</script>

<style lang="less" scoped>
.ticket-lite-card {
	width : 100%;
	.mycard-content {
		position : relative;
		padding-bottom : 2em;
	}
	.drone-name {
		display : inline-block;
		padding : 2px 6px;
		font-size : 0.85em;
		border : 1px dashed @primary-2;
		color : @primary-color;
		margin-right : 2px;
		border-radius: 8px;
	}
	.drone-name-general {
		display : inline-block;
		padding : 2px 6px;
		font-size : 0.85em;
		background-color : @yellow-8;
		color : @white;
		margin-right : 2px;
		border-radius: 8px;
	}
}
.ticket-lite-card::v-deep {
	.ant-card-body {
		padding-bottom : 8px;
	}
}
.header-row {
	font-family: @font-family-title;
}
.topic-row {
	margin : 12px 0 8px;
	font-size : 0.95em;
}
.tags-row {
	margin-top : 4px;
}
.status-row {
	position : absolute;
	bottom : 0;
	padding-top: 4px;

}
.text-detail {
	color : @text-muted;
	font-size : 0.8em;
	vertical-align: middle;
}
.ticket-field-company {
	margin-left : 8px;
	color : @text-muted;
	font-size : 0.85em;
}

</style>
